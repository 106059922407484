import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

import logo from "../../images/herstellung-im-einklang-mit-der-natur.png"

class MobileHeader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isMenuOpen: false,
    }

    this.getMenuToggleClass = this.getMenuToggleClass.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  toggleMenu() {
    var isMenuOpen = !this.state.isMenuOpen
    this.setState({ isMenuOpen: !this.state.isMenuOpen })

    if (typeof this.props.onChange === "function") {
      this.props.onChange(isMenuOpen)
    }
  }

  getMenuToggleClass() {
    let elementClasses = ["toggleMenu"]

    if (this.state.isMenuOpen === true) {
      elementClasses.push("is-active")
    }

    return elementClasses.join(" ")
  }

  render() {
    return (
      <nav
        id="_mobileNav"
        className="navbar is-transparent is-hidden-desktop is-fixed-top"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link className="navbar-item __logo" to="/">
            <h1 className="_logo title">MOONAMAR</h1>
          </Link>
          <a
            role="button"
            className={"navbar-burger " + this.getMenuToggleClass()}
            aria-label="menu"
            aria-expanded="false"
            onClick={this.toggleMenu}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div class={"navbar-menu " + this.getMenuToggleClass()}>
          <div class="navbar-start">
            <Link to="/uber-uns" className="navbar-item">
              Über uns
            </Link>
            <Link to="/produkte" className="navbar-item">
              Produkte
            </Link>
            <Link to="/inspiration" className="navbar-item">
              Inspiration
            </Link>
            <Link to="/partnerschaft" className="navbar-item">
              Partnerschaft
            </Link>
            <Link to="/kontakt" className="navbar-item">
              Kontakt
            </Link>
            <div class="buttons has-addons is-centered">
              <Link to="/es/" className="button">
                ES
              </Link>
              <Link to="/" className="button">
                DE
              </Link>
              <Link to="/bs/" className="button">
                BHS
              </Link>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

MobileHeader.propTypes = {
  siteTitle: PropTypes.string,
}

MobileHeader.defaultProps = {
  siteTitle: ``,
}

export default MobileHeader
